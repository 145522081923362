'use client'

import dynamic from 'next/dynamic'
import { useContext, useState } from 'react'
import clsx from 'clsx'
import type { TypeCtaBackgroundBlockFields } from '@lib/generated-types'
import CtaBackground from '@components/cta-background'
import { routeMapper } from '@lib/mappers/menu-item-mapper'
import { richTextRender } from '@lib/rich-text-base'
import { colWidthCenterMap, ptAtLgMap, pbAtLgMap } from '@lib/token-maps'
import slugify from '@sindresorhus/slugify'
import { modalImportMap } from '@lib/constants'
import { Button, Container } from '@shc/ui'
import { mapButtonVariant } from '@lib/mappers/sharp-ui-mapper'
import useAnalytics, { type SPContext } from '@hooks/use-analytics'
import Analytics from '@components/analytics'
import { BlockContext, PageContext } from '@lib/analytics'

const CtaBackgroundBlock = ({
  internalName,
  sectionLink,
  heading,
  description,
  button1Label,
  button1AnchorOrExternalLink,
  button1Link,
  button1Modal,
  button1Style,
  button2Label,
  button2AnchorOrExternalLink,
  button2Link,
  button2Modal,
  button2Style,
  width,
  backgroundColor,
  backgroundImage,
  paddingTop,
  paddingBottom,
  ...props
}: TypeCtaBackgroundBlockFields) => {
  const { track } = useAnalytics()
  const blockContext = useContext(BlockContext)
  const pageContext = useContext(PageContext)
  const link1 = routeMapper(button1Link, button1AnchorOrExternalLink)
  const link2 = routeMapper(button2Link, button2AnchorOrExternalLink)

  let Button1Modal: any = <div className="hidden" />
  let Button2Modal: any = <div className="hidden" />
  const hasButton1Modal = typeof button1Modal !== 'undefined' ? true : false
  const hasButton2Modal = typeof button2Modal !== 'undefined' ? true : false
  const [isButton1ModalOpen, setIsButton1ModalOpen] = useState(false)
  const [isButton2ModalOpen, setIsButton2ModalOpen] = useState(false)
  const popModal = (
    setModalOpen: (value: boolean) => void,
    buttonLabel: string,
    modalName: string
  ) => {
    setModalOpen(true)
    const baseContexts: SPContext[] = [
      {
        name: 'component',
        data: {
          component_text: buttonLabel,
        },
      },
    ]
    if (pageContext) baseContexts.push(pageContext)
    if (blockContext) baseContexts.push(blockContext)

    track({
      event: { name: 'component_click', data: {} },
      contexts: baseContexts,
    })

    track({
      event: { name: 'modal_open', data: {} },
      contexts: [...baseContexts, { name: 'modal', data: { modal_name: modalName } }],
    })
  }

  if (hasButton1Modal && button1Modal) {
    Button1Modal = dynamic(() => modalImportMap[button1Modal], {
      ssr: false,
    })
  }

  if (hasButton2Modal && button2Modal) {
    // Dynamic import of healthnews sign up
    Button2Modal = dynamic(() => modalImportMap[button2Modal], {
      ssr: false,
    })
  }

  return (
    <Container
      id={typeof sectionLink === 'undefined' ? undefined : slugify(sectionLink)}
      fullWidth="lg"
      className={clsx('grid grid-cols-12', ptAtLgMap[paddingTop], pbAtLgMap[paddingBottom])}
      {...props}>
      <CtaBackground
        title={heading}
        description={richTextRender(description)}
        background={backgroundImage}
        backgroundColor={backgroundColor}
        className={clsx(colWidthCenterMap[width])}>
        {/* button1 non-modal */}
        {button1Label && !hasButton1Modal && (
          <Analytics
            click={{
              name: 'component_click',
              data: {},
            }}
            contexts={[
              {
                name: 'component',
                data: {
                  component_text: button1Label,
                  component_url: link1.route,
                },
              },
            ]}>
            <Button
              href={link1.route}
              target={link1.isInternal ? undefined : '_blank'}
              rel={link1.isInternal ? undefined : 'noopener noreferrer'}
              {...mapButtonVariant(button1Style)}>
              {button1Label}
            </Button>
          </Analytics>
        )}

        {/* button 1 modal  */}
        {button1Label && hasButton1Modal && (
          <Button
            {...mapButtonVariant(button1Style)}
            onClick={() =>
              popModal(setIsButton1ModalOpen, button1Label, button1Modal ?? 'undefined')
            }>
            {button1Label}
          </Button>
        )}

        {/* button2 non-modal */}
        {button2Label && !hasButton2Modal && (
          <Analytics
            click={{
              name: 'component_click',
              data: {},
            }}
            contexts={[
              {
                name: 'component',
                data: {
                  component_text: button2Label,
                  component_url: link2.route,
                },
              },
            ]}>
            <Button
              href={link2.route}
              target={link2.isInternal ? undefined : '_blank'}
              rel={link2.isInternal ? undefined : 'noopener noreferrer'}
              {...mapButtonVariant(button1Style)}>
              {button2Label}
            </Button>
          </Analytics>
        )}

        {/* button 2 modal  */}
        {button2Label && hasButton2Modal && (
          <Button
            {...mapButtonVariant(button1Style)}
            onClick={() =>
              popModal(setIsButton2ModalOpen, button2Label, button2Modal ?? 'undefined')
            }>
            {button2Label}
          </Button>
        )}
      </CtaBackground>
      {hasButton1Modal && (
        <Button1Modal isModalOpen={isButton1ModalOpen} setIsModalOpen={setIsButton1ModalOpen} />
      )}
      {hasButton2Modal && (
        <Button2Modal isModalOpen={isButton2ModalOpen} setIsModalOpen={setIsButton2ModalOpen} />
      )}
    </Container>
  )
}

export default CtaBackgroundBlock
