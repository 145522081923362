import clsx from 'clsx'
import type { ComponentPropsWithoutRef, ReactElement, ReactNode } from 'react'
import useCollapse from 'react-collapsed'
import { getIcon } from '@components/icons'
import Content from './content'
import { Icon, Typography } from '@shc/ui'

export interface CardAccordionProps extends ComponentPropsWithoutRef<'div'> {
  primaryIcon?: string
  title: string
  summary?: string
  icon1?: string
  icon1Text?: string
  icon2?: string
  icon2Text?: string
  button: ReactElement
  accordionDropdownLabel: string
  accordionBody: string | ReactElement | ReactNode
  rightFooterText?: string
  isFeatured?: boolean
  featuredText?: string
  toggleAccordion?: () => void
  isExpanded?: boolean
}

export interface OnToggleParams {
  isExpanded: boolean
}

const CardAccordion = ({
  primaryIcon,
  title,
  summary,
  icon1,
  icon1Text,
  icon2,
  icon2Text,
  button,
  accordionDropdownLabel,
  accordionBody,
  rightFooterText,
  isFeatured,
  featuredText,
  toggleAccordion,
  isExpanded,
  className,
  ...props
}: CardAccordionProps) => {
  const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

  return (
    <div
      className={clsx(
        'bg-gray-50 rounded drop-shadow-md w-full gap-5',
        className,
        isFeatured && 'border-2 border-primary-700'
      )}
      {...props}>
      {featuredText && (
        <div className="text-center bg-primary-700 text-sm font-semibold text-white">
          {featuredText}
        </div>
      )}
      <article className="flex flex-col gap-5 items-center w-full h-full p-6">
        {/*
          To acheive vertical alignment of the content across multiple cards,
          the max height of this div is set to 150px (2 lines of text).
          Contentful 'summary' field is set to max 55 characters to enforce this.
        */}
        <div className="flex flex-col gap-5 w-full max-h-[150px]">
          {primaryIcon && !isFeatured && (
            <Icon icon={getIcon(primaryIcon)} className="text-primary h-6" />
          )}
          {title && (
            <Typography
              variant="h3"
              align="center"
              className={clsx(
                !isFeatured && !primaryIcon && 'pt-[72px]',
                isFeatured && 'pt-[42px]'
              )}>
              {title}
            </Typography>
          )}
          {summary && <div className="text-base font-medium leading-7 text-center">{summary}</div>}
        </div>

        <div className="flex flex-col w-full gap-5">
          <div className="flex flex-col gap-5 items-center w-full">
            <div className="flex flex-row gap-4 md:gap-10 empty:hidden">
              {icon1 && icon1Text && (
                <div className="flex flex-row gap-2 items-center">
                  {icon1 && (
                    <Icon
                      fixedWidth
                      icon={getIcon(icon1)}
                      className="h-4 text-gray-700 text-base"
                    />
                  )}
                  {icon1Text && (
                    <div className="text-gray-700 text-base font-medium">{icon1Text}</div>
                  )}
                </div>
              )}
              {icon2 && icon2Text && (
                <div className="flex flex-row gap-2 items-center">
                  {icon2 && (
                    <Icon
                      fixedWidth
                      icon={getIcon(icon2)}
                      className="h-4 text-gray-700 text-base"
                    />
                  )}
                  {icon2Text && (
                    <div className="text-gray-700 text-base font-medium">{icon2Text}</div>
                  )}
                </div>
              )}
            </div>
            {button}
          </div>

          {/* ACCORDION */}
          <div className="flex flex-col flex-shrink border-t w-full">
            <button
              data-testid="accordion-toggle"
              className="py-5 box-border appearance-none cursor-pointer focus:outline-2 outline-primary outline-offset-2 flex justify-center items-center"
              {...getToggleProps({ onClick: toggleAccordion })}>
              <Typography variant="h5" className="text-left pr-2 text-primary">
                {accordionDropdownLabel}
              </Typography>
              <Icon
                icon="chevron-down"
                className={`${
                  !isExpanded ? '' : '-rotate-180'
                } transform duration-200 flex-shrink h-4 text-primary`}
              />
              {rightFooterText && <div className="flex-grow text-right">{rightFooterText}</div>}
            </button>
            <div {...getCollapseProps()}>
              <Content className="rich-text mb-5">{accordionBody}</Content>
            </div>
          </div>
        </div>
      </article>
    </div>
  )
}

export default CardAccordion
